import React, {Component} from 'react';
import style from "./style.module.scss";
import ReactPlaceholder from "react-placeholder";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import userPhoto from '../../../../../assets/img/cover-icon-user.svg';
import { ReactSVG } from 'react-svg'
import LazyLoad from 'react-lazyload';



const CustomTool = withStyles(theme => ({
    tooltip: {
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 13,

        padding: '5px 10px',
        top: '10px',

    },
}))(Tooltip);

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            cursour: 'default',

            imgUrl: '',
            openHand: false,
            email: '',
            display: 'none'
        }
    }
    componentDidMount() {
        let els = document.querySelector('#a'+this.props.keys)
        if(els.scrollWidth > els.clientWidth) {
            this.setState({
                open: true,
                cursor: 'pointer',
                email: this.props.data.email,
                display: 'block'
            },()=>{
                window.addEventListener('touchstart',(e)=>{
                    if(e.target.innerHTML !== this.props.data.email){
                        this.setState({
                            openHand: false,
                        })
                    }
                }, { passive: true })
            })
        }
    }
    checkLoad=(e)=>{
        e.target.src = userPhoto
    }
    handleTooltipOpen = ()=>{
        console.log('ok')
        this.setState({
            openHand: !this.state.openHand
        })
        console.log(this.state)

    }

    render() {
        const { browser } = this.props;
        if(/Android/g.test(browser.os) || /iOS/g.test(browser.os)) {
            return (
                <Grid item className={style.user + ' user'} xs={12} sm={4} md={4} lg={4} xl={4}>
                    <ReactPlaceholder customPlaceholder={<ReactSVG src={userPhoto} style={{background: '#eeeeee',width: '70px', height: '70px', borderRadius: '50px', display: 'flex', justifyContent: 'center'}}/>} showLoadingAnimation={true} type='round' ready={this.props.load} style={{ width: 70, height: 70}}>
                        <LazyLoad>
                            <div className={style.Image}>
                                <img src={this.props.data.photo} alt={this.props.data.name} onError={this.checkLoad} style={{backgroundColor: '#eeeeee'}}/>
                            </div>
                        </LazyLoad>
                    </ReactPlaceholder>
                    <div className={style.userInfo}>
                        <ReactPlaceholder showLoadingAnimation={true} type='text' style={{width: '120px', paddingTop: '21px'}} rows={3} ready={this.props.load} color='#E0E0E0'>
                            <h4>{this.props.data.name}</h4>
                            <p className="prg3">{this.props.data.position}</p>
                            <CustomTool title={this.state.email} open={this.state.openHand}  disableHoverListener={!this.state.open} disableFocusListener={!this.state.open} disableTouchListener={!this.state.open} style={{display: this.state.display}}>
                                <p id={'a'+this.props.keys} style={{cursor: this.state.cursor}} onClick={this.handleTooltipOpen}  className="prg3 userEmail">{this.props.data.email}</p>
                            </CustomTool>
                            <p className="prg3">{this.props.data.phone}</p>
                        </ReactPlaceholder>
                    </div>
                </Grid>
            );
        }else{
            return (
                <Grid item className={style.user+ ' user'} xs={12} sm={4} md={4} lg={4} xl={4}>
                    <ReactPlaceholder customPlaceholder={<ReactSVG src={userPhoto} style={{background: '#eeeeee',width: '70px', height: '70px', borderRadius: '50px', display: 'flex', justifyContent: 'center'}}/>} showLoadingAnimation={true} type='round' ready={this.props.load} style={{ width: 70, height: 70}}>
                        <LazyLoad>
                            <div className={style.Image}>
                                <img src={this.props.data.photo} alt={this.props.data.name} onError={this.checkLoad} style={{backgroundColor: '#eeeeee'}}/>
                            </div>                        </LazyLoad>
                    </ReactPlaceholder>
                    <div className={style.userInfo}>
                        <ReactPlaceholder showLoadingAnimation={true} type='text' style={{width: '120px', paddingTop: '21px'}} rows={3} ready={this.props.load} color='#E0E0E0'>
                            <h4>{this.props.data.name}</h4>
                            <p className="prg3">{this.props.data.position}</p>
                            <CustomTool title={this.state.email}  disableHoverListener={!this.state.open} disableFocusListener={!this.state.open} disableTouchListener={!this.state.open} style={{display: this.state.display}}>
                                <p id={'a'+this.props.keys} style={{cursor: this.state.cursor}} className="prg3 userEmail">{this.props.data.email}</p>
                            </CustomTool>
                            <p className="prg3">{this.props.data.phone}</p>
                        </ReactPlaceholder>
                    </div>
                </Grid>
            );
        }
    }
}

export default User;
