import React, {Component} from 'react';
import style from './style.module.scss'
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Buttons from '../Buttons'
import UserAPI from "../../UserAPI";
import { withStyles } from '@material-ui/core/styles';
import ReactPlaceholder from 'react-placeholder';
import User from "./User";
import {ReactSVG} from "react-svg";
import userPhoto from "../../../../assets/img/cover-icon-user.svg";
import i18n from '../../../../i18n';


const ColorCircularProgress = withStyles({
    root: {
        color: '#ef6c00',
    },
})(CircularProgress);

class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            load: false,
            page: 1,
            count: 6,
            data: [],
            variantButton: 'outlined',
            dislayButton: 'inline-block',
            dislayLoader: 'none'
        }
    }
    componentDidMount() {



        this.setState({
            load: false,
            dislayButton: 'none',
            dislayLoader: 'inline-block'
        })

        if(document.querySelector('html').clientWidth < 600){
            this.setState({
                count: 3
            },()=>{
                UserAPI.getUsers(this.state.page, this.state.count).then((res)=>res.json()).then((data)=>{
                    this.setState({
                        data: data.users,
                        page: this.state.page + 1,
                        load: data.success,
                        dislayButton: 'inline-block',
                        dislayLoader: 'none'
                    },()=>{


                    })
                })
            })
        }else {
            this.setState({
                count: 6
            },()=>{
                UserAPI.getUsers(this.state.page, this.state.count).then((res)=>res.json()).then((data)=>{
                    this.setState({
                        data: data.users,
                        page: this.state.page + 1,
                        load: data.success,
                        dislayButton: 'inline-block',
                        dislayLoader: 'none'
                    },()=>{

                    })
                })
            })
        }
    }

    checkLoad=(e)=>{
        e.target.src = 'https://cdn2.vectorstock.com/i/1000x1000/21/71/man-icon-vector-25482171.jpg'
    }

    showMore= ()=>{
        this.setState({
            load: false,
            dislayButton: 'none',
            dislayLoader: 'inline-block'
        })
        UserAPI.getUsers(this.state.page, this.state.count).then((res)=>res.json()).then((data)=>{
            console.log(data)
            let newUsers = this.state.data;
            data.users.forEach((el)=>{
                newUsers.push(el)
            })
            this.setState({
                data: newUsers,
                page: this.state.page + 1,
                load: data.success,
                dislayButton: 'inline-block',
                dislayLoader: 'none'
            },()=>{
                console.log(this.state.data)
                if(data.total_pages === data.page){
                    this.setState({
                        variantButton: 'disabled'
                    })
                }

            })
        })
    }

    render() {
        const { browser, lng}  = this.props

        if(this.state.data.length){
            return (
                <div className={style.users}>
                    <div id="users" style={{transform: 'translateY(-44px)'}}></div>
                    <Container maxWidth="lg" className={style.usersContainer}>
                        <h2>{ i18n.t('Our cheerful users', { lng }) }</h2>
                        <h5>{ i18n.t('Attention! Sorting users by registration date', { lng }) }</h5>
                        <Grid container spacing={3} className={style.usersBlock}>
                            {this.state.data.map((user,i)=>(
                                <User browser={browser} data={user} key={i} keys={i} load={this.state.load}/>
                            ))}
                        </Grid>
                        <Buttons variant={this.state.variantButton} style={{display: this.state.dislayButton}} text={ i18n.t('Show more', { lng }) } onClick={this.showMore}/>
                        <ColorCircularProgress style={{display: this.state.dislayLoader}}/>
                    </Container>
                </div>
            );
        }else{
            return (
                <div className={style.users}>
                    <div id="users" style={{transform: 'translateY(-44px)'}}></div>
                    <Container maxWidth="lg" className={style.usersPlaceholderContainer}>
                        <h2>{ i18n.t('Our cheerful users', { lng }) }</h2>
                        <h5>{ i18n.t('Attention! Sorting users by registration date', { lng }) }</h5>
                        <Grid container spacing={3} className={style.usersBlock}>

                            <Grid  item className={style.userPlaceholder} xs={12} sm={4} md={4} lg={4} xl={4}>
                                <ReactPlaceholder customPlaceholder={<ReactSVG src={userPhoto} style={{background: '#eeeeee',width: '70px', height: '70px', borderRadius: '50px', display: 'flex', justifyContent: 'center'}}/>} showLoadingAnimation={true} type='round' ready={this.props.load} style={{ width: 70, height: 70}}>
                                </ReactPlaceholder>
                                <div className={style.userInfo}>
                                    <ReactPlaceholder showLoadingAnimation={true} type='text' style={{width: '150px', paddingTop: '21px'}} rows={3} ready={this.state.load} color='#E0E0E0'>
                                    </ReactPlaceholder>
                                </div>
                            </Grid>
                            <Grid  item className={style.userPlaceholder} xs={12} sm={4} md={4} lg={4} xl={4}>
                                <ReactPlaceholder customPlaceholder={<ReactSVG src={userPhoto} style={{background: '#eeeeee',width: '70px', height: '70px', borderRadius: '50px', display: 'flex', justifyContent: 'center'}}/>} showLoadingAnimation={true} type='round' ready={this.props.load} style={{ width: 70, height: 70}}>
                                </ReactPlaceholder>
                                <div className={style.userInfo}>
                                    <ReactPlaceholder showLoadingAnimation={true} type='text' style={{width: '150px', paddingTop: '21px'}} rows={3} ready={this.state.load} color='#E0E0E0'>
                                    </ReactPlaceholder>
                                </div>
                            </Grid>
                            <Grid  item className={style.userPlaceholder} xs={12} sm={4} md={4} lg={4} xl={4}>
                                <ReactPlaceholder customPlaceholder={<ReactSVG src={userPhoto} style={{background: '#eeeeee',width: '70px', height: '70px', borderRadius: '50px', display: 'flex', justifyContent: 'center'}}/>} showLoadingAnimation={true} type='round' ready={this.props.load} style={{ width: 70, height: 70}}>
                                </ReactPlaceholder>
                                <div className={style.userInfo}>
                                    <ReactPlaceholder showLoadingAnimation={true} type='text' style={{width: '150px', paddingTop: '21px'}} rows={3} ready={this.state.load} color='#E0E0E0'>
                                    </ReactPlaceholder>
                                </div>
                            </Grid>
                            <Grid  item className={style.userPlaceholder} xs={12} sm={4} md={4} lg={4} xl={4}>
                                <ReactPlaceholder customPlaceholder={<ReactSVG src={userPhoto} style={{background: '#eeeeee',width: '70px', height: '70px', borderRadius: '50px', display: 'flex', justifyContent: 'center'}}/>} showLoadingAnimation={true} type='round' ready={this.props.load} style={{ width: 70, height: 70}}>
                                </ReactPlaceholder>
                                <div className={style.userInfo}>
                                    <ReactPlaceholder showLoadingAnimation={true} type='text' style={{width: '150px', paddingTop: '21px'}} rows={3} ready={this.state.load} color='#E0E0E0'>
                                    </ReactPlaceholder>
                                </div>
                            </Grid>
                            <Grid  item className={style.userPlaceholder} xs={12} sm={4} md={4} lg={4} xl={4}>
                                <ReactPlaceholder customPlaceholder={<ReactSVG src={userPhoto} style={{background: '#eeeeee',width: '70px', height: '70px', borderRadius: '50px', display: 'flex', justifyContent: 'center'}}/>} showLoadingAnimation={true} type='round' ready={this.props.load} style={{ width: 70, height: 70}}>
                                </ReactPlaceholder>
                                <div className={style.userInfo}>
                                    <ReactPlaceholder showLoadingAnimation={true} type='text' style={{width: '150px', paddingTop: '21px'}} rows={3} ready={this.state.load} color='#E0E0E0'>
                                    </ReactPlaceholder>
                                </div>
                            </Grid>
                            <Grid  item className={style.userPlaceholder} xs={12} sm={4} md={4} lg={4} xl={4}>
                                <ReactPlaceholder customPlaceholder={<ReactSVG src={userPhoto} style={{background: '#eeeeee',width: '70px', height: '70px', borderRadius: '50px', display: 'flex', justifyContent: 'center'}}/>} showLoadingAnimation={true} type='round' ready={this.props.load} style={{ width: 70, height: 70}}>
                                </ReactPlaceholder>
                                <div className={style.userInfo}>
                                    <ReactPlaceholder showLoadingAnimation={true} type='text' style={{width: '150px', paddingTop: '21px'}} rows={3} ready={this.state.load} color='#E0E0E0'>
                                    </ReactPlaceholder>
                                </div>
                            </Grid>
                        </Grid>
                        <ColorCircularProgress style={{display: this.state.dislayLoader, marginBottom: '40px'}}/>
                    </Container>
                </div>
            );
        }

    }
}

export default Users;
