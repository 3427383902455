import React from "react";
import style from './style.module.scss';
import Container from '@material-ui/core/Container';
import { ReactSVG } from 'react-svg';
import htmlIcon from '../../../../assets/img/html.svg';
import cssIcon from '../../../../assets/img/css.svg';
import jsIcon from '../../../../assets/img/javascript.svg';
import i18n from '../../../../i18n';

const About = (props) => {
    const { lng } = props;

    return(
        <div className={style.about}>
            <div id="relationships" style={{transform: 'translateY(-64px)'}}></div>
            <Container maxWidth="lg" className={style.aboutContainer}>
                <h2>{ i18n.t('About my relationships with web-development', { lng }) }</h2>
                <div className={style.aboutBlocks}>
                    <div className={style.block1}>
                        <ReactSVG src={htmlIcon} loading={() => <div className={style.svgLoad}></div>} alt="abz.agency html"/>
                        <div className={style.blockContent}>
                            <h3>{ i18n.t('I\'m in love with HTML', { lng }) }</h3>
                            <p className="prg2">
                                { i18n.t('Hypertext Markup Language', { lng }) }
                            </p>
                        </div>
                    </div>
                    <div className={style.block2}>
                        <ReactSVG src={cssIcon} loading={() => <div className={style.svgLoad}></div>} alt="abz.agency css"/>
                        <div className={style.blockContent}>
                            <h3>{ i18n.t('CSS is my best friend', { lng }) }</h3>
                            <p className="prg2">
                                { i18n.t('Cascading Style Sheets', { lng }) }
                            </p>
                        </div>
                    </div>
                    <div className={style.block3}>
                        <ReactSVG src={jsIcon} loading={() => <div className={style.svgLoad}></div>} alt="abz.agency javascript"/>
                        <div className={style.blockContent}>
                            <h3>{ i18n.t('JavaScript is my passion', { lng }) }</h3>
                            <p className="prg2">
                                { i18n.t('JavaScript is a high-level', { lng }) }
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default About
