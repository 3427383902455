import React from "react";
import About from "../Components/About";
import Users from "../Components/Users";

const AnotherBlocks = (props) => {
    const { browser, lng} = props;
    return(
        <>
            <About lng={lng} browser={browser}/>
            <Users lng={lng} browser={browser}/>
        </>
    )
}

export default AnotherBlocks
